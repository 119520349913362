import React, { useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import "../styles/PhotoSelector.css";

const MAX_SELECTION = 50;

const PhotoSelector = ({ onClose, onPhotosSelected }) => {
  const [googlePhotos, setGooglePhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [error, setError] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state to track initial loading

  const fetchGooglePhotos = async (pageToken = null) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      if (pageToken) {
        setIsLoadingMore(true); // Show loading spinner when loading more photos
      } else {
        setIsLoading(true); // Show loading spinner for the initial load
      }

      const response = await axios.get("/api/google-photos", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { pageToken },
      });

      setGooglePhotos((prevPhotos) => [...prevPhotos, ...response.data.photos]);
      setNextPageToken(response.data.nextPageToken);
    } catch (error) {
      setError("Error fetching photos from Google Photos");
      console.error(error);
    } finally {
      setIsLoading(false); // Hide loading spinner after fetching
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchGooglePhotos();
  }, []);

  const handlePhotoSelect = (photo) => {
    if (selectedPhotos.length >= MAX_SELECTION) return;

    setSelectedPhotos((prevPhotos) => {
      const alreadySelected = prevPhotos.find((p) => p.id === photo.id);
      if (alreadySelected) {
        return prevPhotos.filter((p) => p.id !== photo.id);
      } else {
        return [...prevPhotos, photo];
      }
    });
  };

  const handleConfirmSelection = () => {
    onPhotosSelected(selectedPhotos);
    onClose();
  };

  const handleLoadMore = () => {
    if (nextPageToken) {
      fetchGooglePhotos(nextPageToken);
    }
  };

  if (error) {
    return (
      <div className="modal-backdrop">
        <div className="photo-selector">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-backdrop">
      <div className="photo-selector">
        <h2>Select Photos</h2>

        {isLoading ? (
          // Show the loading spinner while loading photos
          <div className="loading-spinner">
            <TailSpin
              height="80"
              width="80"
              color="#00BFFF"
              ariaLabel="loading"
            />
          </div>
        ) : (
          <div className="google-photos-grid">
            {googlePhotos.length === 0 ? (
              <p>No photos available</p>
            ) : (
              googlePhotos.map((photo, index) => (
                <div
                  key={index}
                  className={`image-item ${
                    selectedPhotos.some((p) => p.id === photo.id)
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handlePhotoSelect(photo)}
                >
                  <img src={photo.baseUrl} alt="" width="100" />
                  {selectedPhotos.some((p) => p.id === photo.id) && (
                    <div className="checkmark">✔</div>
                  )}
                </div>
              ))
            )}
          </div>
        )}

        {nextPageToken && (
          <button
            onClick={handleLoadMore}
            className="load-more-button"
            disabled={isLoadingMore}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        )}
        <div className="button-group fixed-bottom">
          <button onClick={onClose} className="cancel-button">
            Cancel
          </button>
          <button
            onClick={handleConfirmSelection}
            className="confirm-button"
            disabled={selectedPhotos.length === 0}
          >
            Confirm Selection ({selectedPhotos.length}/{MAX_SELECTION})
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhotoSelector;
