import React from "react";
import "../styles/Home.css";

const Home = () => {
  const handleLogin = () => {
    window.location.href = "/api/auth"; // Redirect directly to your backend to start the OAuth2 flow
  };

  return (
    <div className="homecontainer">
      <header>
        <div className="header-content">
          <h1 className="logo">
            <img src="./eblogo_2.png" alt="ExpenseBot logo" />
          </h1>
          <h2 className="headline">
            ExpenseBot<span className="divider"> - </span>
            <span className="subheadline">Effortless Expense Management</span>
          </h2>
        </div>
      </header>

      <main className="content1">
        <div className="marketing-grid">
          <div className="marketing-item large-icon snap-receipt">
            <img src="./phone3.png" alt="Snap" className="icon" />
            <h3>Snap a Photo of Your Receipt</h3>
            <p>No app required. Just take a photo like you normally would.</p>
          </div>
          <div className="marketing-item large-icon autosync">
            <img src="./spreadsheet.png" alt="Auto-Sync" className="icon" />
            <h3>Auto-Added & Classified</h3>
            <p>
              ExpenseBot scans your photos for receipts, classifies and saves
              them to a Google Sheets spreadsheet we create for you.
            </p>
          </div>
          <div className="marketing-item large-icon ai-processing">
            <img src="./email.png" alt="AI Processing" className="icon" />
            <h3>Daily Notifications</h3>
            <p>ExpenseBot will email you everytime a new receipt is found.</p>
          </div>
        </div>
        <div className="login-section call-to-action">
          <button className="login-button" onClick={handleLogin}>
            Login with Google & Try Free ➜
          </button>
        </div>
      </main>
      <footer className="footerhome">
        <p className="terms">
          By signing up, you agree to the ExpenseBot{" "}
          <a href="/privacy">Privacy Policy</a> and{" "}
          <a href="/terms">Terms of Service</a>. <br /> Read the{" "}
          <a href="/faq">ExpenseBot Faq</a> to see how it works.{" "}
        </p>
      </footer>
    </div>
  );
};

export default Home;
