import React, { useState, useEffect } from "react";
import "../styles/FirstTimeUser.css";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import PhotoSelector from "./PhotoSelector";

const FirstTimeUser = ({ user, setProcessInfo }) => {
  const [loading, setLoading] = useState(!user);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [isPhotoSelectorOpen, setIsPhotoSelectorOpen] = useState(false);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  const handlePhotoSelector = () => {
    setIsPhotoSelectorOpen(true);
  };

  const handlePhotosSelected = async (selectedPhotos) => {
    setIsPhotoSelectorOpen(false);
    setProcessing(true);

    const token = localStorage.getItem("token");
    if (!token) return;

    try {
      const photoIds = selectedPhotos.map((photo) => photo.id);

      const response = await axios.get(`/api/listPhotos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          userSelectedPhotos: JSON.stringify(photoIds),
        },
      });

      const data = response.data;

      if (data.success) {
        setProcessInfo({
          endDate: data.processInfo.endDate,
          spreadsheetId: data.processInfo.spreadsheetId,
          processingCompleted: data.processInfo.processingCompleted,
        });
      }
    } catch (error) {
      let errorMessage = "An error occurred while processing your photos.";
      let errorDetails = "";

      if (error.response) {
        errorMessage += ` Server responded with status ${error.response.status}.`;
        errorDetails = error.response.data.message || error.message;
      } else if (error.request) {
        errorMessage += " No response received from the server.";
        errorDetails = "Please check your network connection and try again.";
      } else {
        errorDetails = error.message;
      }

      setError(`${errorMessage} Details: ${errorDetails}`);
    } finally {
      setProcessing(false);
    }
  };

  const handleContinueWithoutPhoto = async () => {
    setProcessing(true);

    const token = localStorage.getItem("token");
    if (!token) return;

    try {
      const response = await axios.get(`/api/listPhotos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate: new Date().toISOString(), // current date and time in UTC
        },
      });

      const data = response.data;

      if (data.success) {
        setProcessInfo({
          endDate: data.processInfo.endDate,
          spreadsheetId: data.processInfo.spreadsheetId,
          processingCompleted: data.processInfo.processingCompleted,
        });
      }
    } catch (error) {
      let errorMessage = "An error occurred while fetching data.";
      let errorDetails = "";

      if (error.response) {
        errorMessage += ` Server responded with status ${error.response.status}.`;
        errorDetails = error.response.data.message || error.message;
      } else if (error.request) {
        errorMessage += " No response received from the server.";
        errorDetails = "Please check your network connection and try again.";
      } else {
        errorDetails = error.message;
      }

      setError(`${errorMessage} Details: ${errorDetails}`);
    } finally {
      setProcessing(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="firsttime-container">
      <header className="header">
        <div className="header-content">
          <h2 className="main-header">Select a Photo of a Receipt</h2>
        </div>
      </header>

      <main className="content">
        <div className="instructions">
          <p>Select a photo of a receipt to see how ExpenseBot works.</p>
        </div>

        <div className="image-container">
          <img
            src="./selectphoto.png"
            alt="Select a receipt from your camera roll"
            className="receipt-demo-image"
          />
        </div>

        <div className="call-to-action">
          <button
            className="start-button"
            onClick={handlePhotoSelector}
            disabled={processing}
          >
            {processing ? (
              <TailSpin color="#FFF" height={20} width={20} />
            ) : (
              "Select Photo of a Receipt"
            )}
          </button>

          <button
            className="continue-button"
            onClick={handleContinueWithoutPhoto}
            disabled={processing}
          >
            {processing ? (
              <TailSpin color="#FFF" height={20} width={20} />
            ) : (
              "Continue without Photo"
            )}
          </button>

          {error && <p className="error-text">{error}</p>}
        </div>
      </main>

      <footer className="footerfirst">
        <div className="footer-content">
          <img
            src="./eblogo_2.png"
            alt="ExpenseBot logo"
            className="footer-logo"
          />
          <p className="footer-text">Powered by ExpenseBot</p>
        </div>
      </footer>

      {isPhotoSelectorOpen && (
        <div className="modal-backdrop">
          <PhotoSelector
            onClose={() => setIsPhotoSelectorOpen(false)}
            onPhotosSelected={handlePhotosSelected}
          />
        </div>
      )}
    </div>
  );
};

export default FirstTimeUser;
